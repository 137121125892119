import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<link rel=\"stylesheet\" href=\"{{this.globalVars.iconsCssUrl}}\"/>\n", {"contents":"<link rel=\"stylesheet\" href=\"{{this.globalVars.iconsCssUrl}}\"/>\n","moduleName":"backoffice/components/generic-components/styles/sb-styles.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/styles/sb-styles.hbs"}});
import Component from "@glimmer/component";
import {tracked} from "@glimmer/tracking";
import {inject as service} from "@ember/service";
import {task} from "ember-concurrency";
import moment from "moment";

export default class GenericComponentsStylesSbStylesComponent extends Component {
  @service globalVars;
  @service ajax;

  @tracked iconsCssUrl;

  constructor() {
    super(...arguments);
  }

  @task
  * getUrlCssTask() {
    yield this.ajax.request(`${this.globalVars.url.site_api}/icon/css`)
      .then(result => {
        this.globalVars.iconsCssUrl = `${result}?v=${moment().unix()}`;
      })
  }
}
