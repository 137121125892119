import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"controller-item {{if this.isActive 'active'}}\" onclick={{action @setPromotion @promotion}}>\n  <div class=\"left-section text-ellipsis\">\n    <div class=\"icon\" {{sortable-handle}}>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n    </div>\n\n    <div class=\"name-info text-ellipsis\">\n      <div class=\"name text-ellipsis\">{{@promotion.bonus.bonus_code}}</div>\n      {{#if @promotion.promotionByLanguage.body}}\n        <div class=\"description text-ellipsis\">{{{@promotion.promotionByLanguage.body}}}</div>\n      {{/if}}\n    </div>\n  </div>\n</div>\n{{yield}}", {"contents":"<div class=\"controller-item {{if this.isActive 'active'}}\" onclick={{action @setPromotion @promotion}}>\n  <div class=\"left-section text-ellipsis\">\n    <div class=\"icon\" {{sortable-handle}}>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n      <span></span>\n    </div>\n\n    <div class=\"name-info text-ellipsis\">\n      <div class=\"name text-ellipsis\">{{@promotion.bonus.bonus_code}}</div>\n      {{#if @promotion.promotionByLanguage.body}}\n        <div class=\"description text-ellipsis\">{{{@promotion.promotionByLanguage.body}}}</div>\n      {{/if}}\n    </div>\n  </div>\n</div>\n{{yield}}","moduleName":"backoffice/components/route-components/bonuses/promotion-pages/order/order-item/sb-promotion-controller.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/promotion-pages/order/order-item/sb-promotion-controller.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsBonusesPromotionPagesPromotionControllerSbPromotionControllerComponent extends Component {

  get isActive() {
    if (this.args.promotion && !this.args.promotion.id) {
      return true;
    }
    if (this.args.selectedPromotion && this.args.promotion) {
      return this.args.selectedPromotion.id === this.args.promotion.id;
    }
    return null;
  }
}
