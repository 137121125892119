import Model, {attr, belongsTo} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';

const Validations = buildValidations({
  info: {
    description: 'Promotion info',
    validators: [
      validator('dependant-field-presence', {
        dependentField: 'description',
        condition: 'present',
        message: 'Please add a promotion info',
      }),
      validator('length-without-chars', {
        max: 250,
        ignoredChars: ['\n', '\r']
      }),
    ],
  },
});

export default class PromotionByLanguageModel extends Model.extend(Validations) {
  @attr('date') created_at;
  @attr('image') image;
  @attr('number') promotion_id;
  @attr('string') body;
  @attr('string') description;
  @attr('string') info;
  @attr('string') language_id;
  @attr('string') url;

  @belongsTo('language', {async: false, inverse: null}) language;
}
