import Model, {attr, belongsTo} from '@ember-data/model';
import {buildValidations, validator} from 'ember-cp-validations';

const Validations = buildValidations({
  bonus_id: {
    description: 'Bonus',
    validators: [
      validator('presence', true)
    ],
  },

  image_bg: {
    description: 'Background image',
    validators: [
      validator('presence', true)
    ],
  },

  image_front: {
    description: 'Front image',
    validators: [
      validator('presence', true)
    ],
  }
});

export default class CrmPopupModel extends Model.extend(Validations) {
  @attr('number') bonus_id;
  @attr('number') partner_id;
  @attr('string') image_bg;
  @attr('string') image_front;
  @attr('string', {defaultValue: 'registration'}) type;

  @belongsTo('bonus', { async: false, inverse: null }) bonus
}
