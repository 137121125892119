import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';

const LengthWithoutChars = class BaseValidatorClass extends BaseValidator {
  @service intl;

  validate(value, options) {
    if (!value || typeof value !== 'string') {
      return true;
    }

    const fieldName = options?.description || 'This field';
    const {ignoredChars = [], ignoredPattern, min = 0, max} = options;
    const regex = new RegExp(`[${ignoredChars.map(char => char.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')).join('')}]`, 'g');
    const cleanValue = ignoredPattern
      ? value.replace(ignoredPattern, '')
      : value.replace(regex, '');
    const length = cleanValue.length;

    if (min && length < min) {
      return this.intl.t(`${fieldName} is too short (minimum is ${min} characters)`);
    }

    if (max && length > max) {
      return this.intl.t(`${fieldName} is too long (maximum is ${max} characters)`);
    }

    return true;
  }
};

LengthWithoutChars.reopenClass({
  getDependentsFor() {
    return [];
  },
});

export default LengthWithoutChars;
