import BaseValidator from 'ember-cp-validations/validators/base';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

const DependantFieldPresence = class BaseValidatorClass extends BaseValidator {
  @service intl;

  validate(value, options, model) {
    const fieldName = options?.description || 'This field';
    const { dependentField, condition = 'present', message } = options;
    const dependentValue = model.get(dependentField)?.trim();

    let isConditionMet;
    switch (condition) {
      case 'present': // Dependent field is filled
        isConditionMet = !isEmpty(dependentValue);
        break;
      case 'absent': // Dependent field is empty
        isConditionMet = isEmpty(dependentValue);
        break;
      default:
        throw new Error(`Unknown condition type: ${condition}. Use 'present' or 'absent'.`);
    }

    if (isConditionMet) {
      const trimmedValue = value?.trim();

      if (isEmpty(trimmedValue)) {
        return message || this.intl.t(`${fieldName} required when ${dependentField} is ${condition}.`);
      }
    }

    return true;
  }
};

DependantFieldPresence.reopenClass({
  getDependentsFor(attribute, options) {
    const { dependentField } = options;
    return dependentField ? [`model.${dependentField}`] : [];
  }
});

export default DependantFieldPresence;
