import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<textarea\n  {{did-insert this.initializeEditor}}\n  {{will-destroy this.destroyEditor}}\n>\n  {{{yield}}}\n</textarea>\n", {"contents":"<textarea\n  {{did-insert this.initializeEditor}}\n  {{will-destroy this.destroyEditor}}\n>\n  {{{yield}}}\n</textarea>\n","moduleName":"backoffice/components/generic-components/text-editor/sb-text-editor.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/text-editor/sb-text-editor.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/models/dom';

export default class GenericComponentsTextEditorSbTextEditorComponent extends Component {
  @tracked editor;

  @action
  initializeEditor(element) {
    const font_size_formats = Array.from({ length: 43 }, (v, k) => `${k + 8}px`).join(' ')
    const line_height_formats = '1 1.2 1.4 1.6 1.8 2 2.2 2.4 2.6 2.8 3 3.2 3.4 3.6 3.8 4'

    tinymce.init({
      target: element,
      base_url: '/assets/tinymce',
      license_key: 'gpl',
      menubar: false,
      height: '300px',
      toolbar_mode: 'wrap',
      toolbar: `bold italic underline strikethrough
      | alignleft aligncenter alignright alignjustify
      | forecolor
      | blocks
      | fontfamily fontsize lineheight
      | removeformat`,
      font_size_formats,
      line_height_formats,
      font_family_formats: `
        DM Sans=DM Sans, sans-serif;
        Bowlby One=Bowlby One, sans-serif;
        Arial=arial,helvetica,sans-serif;
        Arial Black=arial black,avant garde;
        Book Antiqua=book antiqua,palatino;
        Courier New=courier new,courier,monospace;
        Georgia=georgia,palatino;
        Helvetica=helvetica;
        Impact=impact,chicago;
        Tahoma=tahoma,arial,helvetica,sans-serif;
        Times New Roman=times new roman,times,serif;
        Trebuchet MS=trebuchet ms,geneva;
        Verdana=verdana,geneva;
        Symbol=symbol;
        Webdings=webdings;
        Wingdings=wingdings,zapf dingbats;
      `,
      content_style: `@import url('https://fonts.googleapis.com/css2?family=Bowlby+One&family=DM+Sans:opsz,wght@9..40,700&display=swap');`,
      setup: (editor) => {
        /**
         * Change Text Editor content if content has changed from outside Text Editor
         * @type {MutationObserver}
         */
        const observer = new MutationObserver(() => {
          if (this.editor && this.editor.getContent() !== element.value.trim()) {
            this.editor.setContent(element.value.trim());
          }
        });

        observer.observe(element, {
          subtree: true,
          characterDataOldValue: true
        });

        this.editor = editor;

        if (this.args.onUpdate) {
          editor.on('input change', () => {
            this.args.onUpdate(editor.getContent());
          });
        }
      }
    });
  }

  @action
  destroyEditor() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  }
}
