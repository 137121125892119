import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class=\"{{this.className}}\"\n  {{on \"focusout\" this.onFocusOut}}\n>\n  {{#if @label}}\n    <label class=\"label\">{{@label}}</label>\n  {{/if}}\n\n  {{yield}}\n\n  {{#if this.shouldDisplayValidation}}\n    <div class='field-box__hint'>\n      <span>{{this.message}}</span>\n    </div>\n  {{/if}}\n</div>\n", {"contents":"<div\n  class=\"{{this.className}}\"\n  {{on \"focusout\" this.onFocusOut}}\n>\n  {{#if @label}}\n    <label class=\"label\">{{@label}}</label>\n  {{/if}}\n\n  {{yield}}\n\n  {{#if this.shouldDisplayValidation}}\n    <div class='field-box__hint'>\n      <span>{{this.message}}</span>\n    </div>\n  {{/if}}\n</div>\n","moduleName":"backoffice/components/layout-components/field-box/sb-field-box.hbs","parseOptions":{"srcName":"backoffice/components/layout-components/field-box/sb-field-box.hbs"}});
import Component from '@glimmer/component';
import {action, computed, set} from '@ember/object';
import {tracked} from '@glimmer/tracking';

export default class LayoutComponentsFieldBoxSbFieldBoxComponent extends Component {
  @tracked canShowValidation = false;

  mainCssClass = 'field-box'

  @computed('args.{className,fullWidth,loading}', 'isInvalid', 'mainCssClass', 'shouldDisplayValidation')
  get className() {
    let classes = [this.mainCssClass];

    if (this.args.className) {
      classes.push(this.args.className)
    }

    if (this.args.loading) {
      classes.push(`${this.mainCssClass}_loading`)
    }

    if (this.isInvalid && this.shouldDisplayValidation) {
      classes.push(`${this.mainCssClass}_error`)
    }

    if(this.args.fullWidth) {
      classes.push(`${this.mainCssClass}_full-width`)
    }

    return classes.join(' ')
  }

  @computed('args.isValid', 'args.validation.isInvalid')
  get isInvalid () {
    if (this.args.validation?.isInvalid !== undefined) {
      return this.args.validation.isInvalid
    }

    if (this.args.isValid !== undefined) {
      return !this.args.isValid
    }

    return false
  }

  @computed('args.{isValid,showValidation,validation}', 'canShowValidation', 'isInvalid')
  get shouldDisplayValidation() {
    if (this.args.validation === undefined && this.args.isValid === undefined) {
      return false;
    }

    if (this.args.showValidation !== undefined) {
      return this.args.showValidation && this.isInvalid
    }

    if (this.canShowValidation) {
      return this.isInvalid
    }

    return false
  }

  @computed('args.message', 'args.validation.message')
  get message() {
    return this.args.validation?.message || this.args.message
  }

  @action
  onFocusOut() {
    set(this, 'canShowValidation', true);
  }
}
