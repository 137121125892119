import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<label class={{this.className}} data-state=\"{{if @checked 'switch-on' 'switch-off'}}\">\n  <input\n    type=\"checkbox\"\n    disabled={{if @disabled 'disabled'}}\n    checked={{@checked}}\n    {{on \"change\" @onChange}}\n    {{on \"click\" @onClick}}\n  />\n  <span></span>\n</label>\n", {"contents":"<label class={{this.className}} data-state=\"{{if @checked 'switch-on' 'switch-off'}}\">\n  <input\n    type=\"checkbox\"\n    disabled={{if @disabled 'disabled'}}\n    checked={{@checked}}\n    {{on \"change\" @onChange}}\n    {{on \"click\" @onClick}}\n  />\n  <span></span>\n</label>\n","moduleName":"backoffice/components/generic-components/switch/sb-switch.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/switch/sb-switch.hbs"}});
import Component from '@glimmer/component';

export default class GenericComponentsSwitchSbSwitchComponent extends Component {
  get className() {
    let classes = ['on-off-button'];

    if (this.args.className) {
      classes.push(this.args.className)
    }

    if (this.args.disabled) {
      classes.push('on-off-button_disabled')
    }

    if (this.args.withOffRed) {
      classes.push('on-off-button_with_off-red')
    }

    return classes.join(' ')
  }
}
