import Service, {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';

export default class ThemeService extends Service {
  @service partner;
  @service store;

  stylePresets = {
    light: [
      {title: '--tournament-font-color', key: '--tournament-font-color', color: '#000000'},
      {title: '--tournament-table-stripe-color', key: '--tournament-table-stripe-color', color: '#EBEBEB'},
      {title: '--tournament-first-place-color', key: '--tournament-first-place-color', color: '#baa460'},
      {title: '--tournament-second-place-color', key: '--tournament-second-place-color', color: '#8988a5'},
      {title: '--tournament-third-place-color', key: '--tournament-third-place-color', color: '#cc886d'},

      {title: '--website-registration-font-color', key: '--website-registration-font-color', color: '#666666'},
      {title: '--website-registration-field-background', key: '--website-registration-field-background', color: '#ebebeb'},
      {title: '--website-font-color', key: '--website-font-color', color: '#000000'},
      {title: '--website-table-stripe-color', key: '--website-table-stripe-color', color: '#EBEBEB'},
      {title: '--website-deposit-modal-text-color', key: '--website-deposit-modal-text-color', color: '#2A2A2A'},
      {title: '--website-deposit-modal-button-border-color', key: '--website-deposit-modal-button-border-color', color: '#2A2A2A'},
      {title: '--website-deposit-modal-more-info-text-color', key: '--website-deposit-modal-more-info-text-color', color: '#FFFFFF'},
      {title: '--website-deposit-modal-more-info-bg-color', key: '--website-deposit-modal-more-info-bg-color', color: '#2A2A2A'},
      {title: '--website-deposit-modal-more-info-table-stripe-color', key: '--website-deposit-modal-more-info-table-stripe-color', color: '#1D1D1D'},
      {title: '--website-bottom-bar-shadow-color', key: '--website-bottom-bar-shadow-color', color: 'rgba(0,0,0,0.1)'},
    ],
    dark: [
      {title: '--tournament-font-color', key: '--tournament-font-color', color: '#FFFFFF'},
      {title: '--tournament-table-stripe-color', key: '--tournament-table-stripe-color', color: '#1D1D1D'},
      {title: '--tournament-first-place-color', key: '--tournament-first-place-color', color: '#f7e6aa'},
      {title: '--tournament-second-place-color', key: '--tournament-second-place-color', color: '#c1bfd9'},
      {title: '--tournament-third-place-color', key: '--tournament-third-place-color', color: '#f3d4c2'},

      {title: '--website-registration-font-color', key: '--website-registration-font-color', color: '#ffffff'},
      {title: '--website-registration-field-background', key: '--website-registration-field-background', color: '#ffffff'},
      {title: '--website-font-color', key: '--website-font-color', color: '#FFFFFF'},
      {title: '--website-table-stripe-color', key: '--website-table-stripe-color', color: '#1D1D1D'},
      {title: '--website-deposit-modal-text-color', key: '--website-deposit-modal-text-color', color: '#FFFFFF'},
      {title: '--website-deposit-modal-button-border-color', key: '--website-deposit-modal-button-border-color', color: '#FFFFFF'},
      {title: '--website-deposit-modal-more-info-text-color', key: '--website-deposit-modal-more-info-text-color', color: '#2A2A2A'},
      {title: '--website-deposit-modal-more-info-bg-color', key: '--website-deposit-modal-more-info-bg-color', color: '#FFFFFF'},
      {title: '--website-deposit-modal-more-info-table-stripe-color', key: '--website-deposit-modal-more-info-table-stripe-color', color: '#EBEBEB'},
      {title: '--website-bottom-bar-shadow-color', key: '--website-bottom-bar-shadow-color', color: 'transparent'},
    ],
  };

  @task(function* () {
    return yield this.store
      .queryRecord('partner-style', {
        active_menu: 'dark',
        partner_id: this.partner.selected_id,
      })
      .catch(({payload}) => {
        if (payload && payload.errors) {
          payload.errors.forEach((error) => {
            this.notifications.error(error, {
              autoClear: true,
            });
          });
        }
      });
  })
  getPartnerStylesTask;
}
