import Service, {inject as service} from '@ember/service';
import {action, set} from '@ember/object';
import {task} from "ember-concurrency";

export default class AuthService extends Service {
  @service ajax;
  @service session;
  @service globalVars;

  statusAwaitingTotpSetup = 'awaiting_totp_setup'
  statusAwaitingTotp = 'awaiting_totp'

  statuses = [
    this.statusAwaitingTotpSetup,
    this.statusAwaitingTotp
  ]

  @action
  setCurrentState(value) {
    if(this.availableStates.includes(+value)) {
      set(this, 'currentState', value);
    }
  }

  @task
  * preAuthTask(username, password) {
    const data = {
      grant_type: 'password',
      username: username,
      password: password
    };
    return yield this.ajax.post(`${this.globalVars.url.admin_api}/admin-auth/login`, {data});
  }
}
