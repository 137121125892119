import ApplicationAdapter from './application';

export default class PromotionAdapter extends ApplicationAdapter {
  urlForFindAll() {
    return `${this.host}/promotionPages/promotions/get`
  }

  urlForQuery() {
    return `${this.host}/promotionPages/promotions/get`
  }

  urlForUpdateRecord(id, model, params) {
    if(params.__attributes._isPromotionUpdate){
      return `${this.host}/promotionPages/promotion-update/${id}`;
    }

    return `${this.host}/promotionPages/promotion/${id}`;
  }
}
