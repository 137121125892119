import BaseValidator from 'ember-cp-validations/validators/base';
import { isPresent } from '@ember/utils';
import {inject as service} from '@ember/service';

const FloatValidator = class FloatValidator extends BaseValidator {
  @service intl;
  @service math;

  validate(value, options) {
    const fieldName = options?.description || 'This field';
    if (!isPresent(value)) {
      return true;
    }

    const floatRegex = /^-?\d+(\.\d+)?$/;
    if (!floatRegex.test(value)) {
      return this.intl.t(`${fieldName} must be a valid float number`);
    }

    if(this.math.isInt(options?.gt) && parseFloat(value) <= options.gt) {
      return this.intl.t(`${fieldName} must be greater than 0`);
    }

    return true;
  }
}

FloatValidator.reopenClass({
  getDependentsFor() {
    return [];
  }
});

export default FloatValidator;
