import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (eq this.tag 'a')}}\n  <a\n    class={{this.classes}}\n    ...attributes\n  >\n    {{yield}}\n  </a>\n{{else}}\n  <button\n    type={{this.type}}\n    class={{this.classes}}\n    disabled={{@disabled}}\n    ...attributes\n  >\n    {{yield}}\n  </button>\n{{/if}}\n", {"contents":"{{#if (eq this.tag 'a')}}\n  <a\n    class={{this.classes}}\n    ...attributes\n  >\n    {{yield}}\n  </a>\n{{else}}\n  <button\n    type={{this.type}}\n    class={{this.classes}}\n    disabled={{@disabled}}\n    ...attributes\n  >\n    {{yield}}\n  </button>\n{{/if}}\n","moduleName":"backoffice/components/generic-components/button/sb-button.hbs","parseOptions":{"srcName":"backoffice/components/generic-components/button/sb-button.hbs"}});
import Component from '@glimmer/component';

export default class MainButton extends Component {
  mainCssClass = 'c-btn'
  mainType = 'button'


  get classes() {
    let classes = [this.mainCssClass];
    const modificators = [
      'active',
      'color',
      'disabled',
      'full',
      'icon',
      'link',
      'loading',
      'round',
      'size',
      'text',
    ]
    const addModificator = (modificator) => {
      classes.push(`${this.mainCssClass}_${modificator}`);
    };

    modificators.forEach(modificator => {
      if (['active', 'disabled', 'loading'].includes(modificator)) {
        if (this.args[modificator]) {
          addModificator(modificator)
        }
      } else if (['color', 'size'].includes(modificator)) {
        if (this.args[modificator]) {
          addModificator(`${modificator}_${this.args[modificator]}`)
        }
      } else if (this.args[modificator] != null) {
        addModificator(modificator)
      }
    })

    return classes.join(' ');
  }

  get tag() {
    if (this.args.link) {
      return 'a';
    }

    return this.args.tag || 'button';
  }

  get type() {
    return this.args.type || this.mainType
  }
}
