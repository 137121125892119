import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"left-panel\">\n  <ul class=\"nav-wrapper {{if this.isDesktop 'native-scroll-box disableX-scroll y-scroll small'}}\">\n    {{#each (sort-by this.navigation.siteContent 'order') as |content|}}\n      {{#if (and (can content.definer) (eq content.type 'menu'))}}\n        <li>\n          <a\n            class=\"nav-item {{if (eq content.id this.navigation.selected) 'selected'}}\"\n            {{on 'click' (fn this.navigation.setActiveMenu content.id)}}\n            id = \"{{content.front_id}}\"\n          >\n            <span class=\"sb-icon sb-{{content.definer}}\"><span class=\"path1\"></span><span class=\"path2\"></span></span>\n            <span class=\"name\">{{t content.title}}</span>\n          </a>\n        </li>\n      {{/if}}\n    {{/each}}\n  </ul>\n</div>\n{{yield}}", {"contents":"<div class=\"left-panel\">\n  <ul class=\"nav-wrapper {{if this.isDesktop 'native-scroll-box disableX-scroll y-scroll small'}}\">\n    {{#each (sort-by this.navigation.siteContent 'order') as |content|}}\n      {{#if (and (can content.definer) (eq content.type 'menu'))}}\n        <li>\n          <a\n            class=\"nav-item {{if (eq content.id this.navigation.selected) 'selected'}}\"\n            {{on 'click' (fn this.navigation.setActiveMenu content.id)}}\n            id = \"{{content.front_id}}\"\n          >\n            <span class=\"sb-icon sb-{{content.definer}}\"><span class=\"path1\"></span><span class=\"path2\"></span></span>\n            <span class=\"name\">{{t content.title}}</span>\n          </a>\n        </li>\n      {{/if}}\n    {{/each}}\n  </ul>\n</div>\n{{yield}}","moduleName":"backoffice/components/main-components/left-panel/sb-left-panel.hbs","parseOptions":{"srcName":"backoffice/components/main-components/left-panel/sb-left-panel.hbs"}});
import Component from '@glimmer/component';
import {inject as service} from '@ember/service';

export default class MainComponentsLeftPanelSbLeftPanelComponent extends Component {
  @service navigation;
  @service media;

  get isDesktop() {
    return !(this.media.isMobile || this.media.isTablet);
  }
}
