import ApplicationAdapter from './application';

export default class CrmPopupAdapter extends ApplicationAdapter {
  urlForQueryRecord() {
    return `${this.host}/cms/crm-popup`
  }

  urlForCreateRecord() {
    return `${this.host}/cms/crm-popup`
  }

  urlForUpdateRecord(id) {
    return `${this.host}/cms/crm-popup/${id}`;
  }
}
