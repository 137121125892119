import Model, {attr, belongsTo} from '@ember-data/model';
import {tracked} from '@glimmer/tracking'

export default class SiteContentModel extends Model {
  @attr parent_id;
  @attr type;
  @attr definer;
  @attr title;
  @attr front_id;
  @attr('number') order;

  @belongsTo('role-permission', { async: false, inverse: null }) rolePermission

  @tracked checked = null;

  @tracked is_favorite = false;
}
