import Service, {inject as service} from '@ember/service';
import {task} from 'ember-concurrency';
import {tracked} from '@glimmer/tracking';
import {set} from '@ember/object';

export default class DelasportService extends Service {
  @service currentPlayer;
  @service globalVars;
  @service ajax;

  @tracked delasportIntegrationLink = '';
  @tracked cash

  delasportPlayerBalanceEndpoint = 'sports/get-balance';

  async fetchPlayerBalance() {
    try {
      const {balance} = await this.getSportsBalanceTask.perform();
      set(this.currentPlayer, 'sportsCash', balance.cash);
      set(this.currentPlayer, 'sportsBonus', balance.bonus);
      set(this.currentPlayer, 'sportsTotal', balance.total);
    } catch (error) {
      console.error(error.message);
    }
  }

  @task
  * getSportsBalanceTask(playerId) {
    return yield this.ajax.request(`${this.globalVars.url.admin_api}/${this.delasportPlayerBalanceEndpoint}/${playerId}`);
  }
}
