import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}\n", {"contents":"<GenericComponents::BasicNavMenu::SbBasicNavMenu @menuItems={{this.menuItems}}/>\n{{yield}}\n","moduleName":"backoffice/components/route-components/bonuses/promotion-pages/sb-promotion-pages.hbs","parseOptions":{"srcName":"backoffice/components/route-components/bonuses/promotion-pages/sb-promotion-pages.hbs"}});
import Component from '@glimmer/component';

export default class RouteComponentsBonusesPromotionPagesSbPromotionPagesComponent extends Component {
  menuItems = [
    {
      name: 'Pages',
      definer: 'bonuses_promotion_pages',
      linkTo: {
        path: 'bonuses.promotion-pages.pages',
      },
    },
    {
      name: 'Order',
      definer: 'bonuses_promotion_pages',
      linkTo: {
        path: 'bonuses.promotion-pages.order',
      },
    },
  ];
}
