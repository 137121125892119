import AuthenticatedRoute from '../authenticated';

export default class BonusesPromotionPagesRoute extends AuthenticatedRoute {
  definer = 'bonuses_promotion_pages';

  activate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = false;
  }

  deactivate() {
    this.partner.showPartnerSelect = true;
    this.partner.showPartnerCurrencySelect = true;
  }
}
